<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>

     <div class="container-fluid mt--6">
        <form>
              <div class="col-md-12">
                <card>
                  <h3 slot="header" class="mb-0">Planos {{typeSub}}</h3>
                  <div class="col-md-12 text-right mb-3 pr-5">
                        <base-button type="neutral" icon size="sm" @click="newPlan">
                            <span class="btn-inner--icon"><i class="fas fa-wallet"></i></span>
                            <span class="btn-inner--text">Novo Plano</span>
                        </base-button>
                  </div>   
                  <table class="table table-responsive font-tabela-peq">
                      <thead class="thead">
                          <tr>
                              <th scope="col">Id do plano</th>
                              <th scope="col">Nome</th>
                              <th scope="col">R$ Taxa Adesão</th>
                              <th scope="col">R$ Valor Titular</th>
                              <th scope="col">R$ Valor Dependente</th>
                              <th scope="col">Possui Fidelidade?</th>
                              <th scope="col">Permite Dependente?</th>
                              <th scope="col">Ativo?</th>
                              <th scope="col">Disp. Site?</th>
                              <th scope="col">Renovação automática?</th>
                              <th scope="col">Retentar pagamento?</th>
                              <th scope="col">Quantidade de tentativas</th>
                              <th scope="col">Quantidade de vidas limitada</th>
                              <th scope="col">Quantidade de vidas</th>
                              <th scope="col"></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="plan in plans" :key="plan.id">
                             <td class="text-center">{{plan.id}}</td>
                              <td><a href="javascript:void(0);" @click.prevent="open(plan.id)">{{plan.name}}</a></td>
                              <td class="text-center">{{plan.accessionFeeFmt}}</td>
                              <td class="text-center">{{plan.priceHolderFmt}}</td>
                              <td class="text-center">{{plan.priceDependentFmt}}</td>
                              <td class="text-center">
                                  <span v-if="plan.hasfidelity">Sim</span>
                                  <span v-if="!plan.hasfidelity">Não</span>
                              </td>
                              <td class="text-center">
                                  <span v-if="plan.hasdependent">Sim</span>
                                  <span v-if="!plan.hasdependent">Não</span>
                              </td>
                              <td class="text-center">
                                  <span v-if="plan.isActive">Sim</span>
                                  <span v-if="!plan.isActive">Não</span>
                              </td>
                              <td class="text-center">
                                  <span v-if="plan.avaliableOnline">Sim</span>
                                  <span v-if="!plan.avaliableOnline">Não</span>
                              </td>
                              <td class="text-center">
                                  <span v-if="plan.automaticRenovation">Sim</span>
                                  <span v-if="!plan.automaticRenovation">Não</span>
                              </td>
                              <td class="text-center">
                                  <span v-if="plan.retry">Sim</span>
                                  <span v-if="!plan.retry">Não</span>
                              </td>
                              <td class="text-center">
                                  {{plan.attempts}}
                              </td>

                              <td class="text-center">
                                  <span v-if="plan.hasLimitDependent">Sim</span>
                                  <span v-if="!plan.hasLimitDependent">Não</span>
                              </td>

                              <td class="text-center">
                                  <span v-if="plan.hasLimitDependent">
                                      {{plan.limitDependent}}
                                  </span>
                                  <span v-if="!plan.hasLimitDependent">-</span>
                              </td>

                              <td>
                                  <a href="#" title="Alterar plano" @click.prevent="open(plan.id)"><i class="far fa-folder-open"></i></a>
                              </td>
                          </tr>
                          <tr v-show="plans.length == 0">
                              <td colspan="8">
                                <center>Nenhum plano disponível.</center>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </card> 
              </div>   

        </form>   
   </div> <!--- fecha conteudo -->  

  </div>
</template>
<script>
export default {
  props: ['typeSub'],
  mounted(){
    this.loadPlans()
  },
  data() {
    return {
       plans : []
    };
  },
  methods:{
    newPlan(){
        location.href = '/configuracoes/plano/novo/'+this.typeSub
    },
    open(id){
        location.href = '/configuracoes/plano/editar/'+id
    },
    loadPlans(){
          this.$clubApi.get('/plan/list/all/'+this.typeSub)
          .then((response) => { 
              this.plans = response.data.object
          }) .catch((error) => {
            this.$notify({type: 'warning', message: error.response.data.msg})
          }).finally(() =>{
              NProgress.done() 
          })
    }
  }
};
</script>
<style></style>
